<template lang="pug">
.basket__step
  h2.basket__title.title Личные данные
  .basket__area.basket__area_info
    .basket__wrapper
      label.basket__label.text(for="user-phone") Телефон
      .basket__input-box
        input.basket__input.input.text(type="tel", id="user-phone", pattern="[0-9-+() ]*", required, v-model="phone", @input="eventChangePhone")
        span.basket__small-text Номер используется для подтверждения заказа
    .basket__wrapper
      label.basket__label.text(for="user-name") Имя и фамилия
      .basket__input-box
        input.basket__input.input.text(id="user-name", v-model="name", @input="eventChangeName")
        span.basket__small-text Может потребоваться для получения заказа
    .basket__wrapper
      label.basket__label.text(for="user-email") Электронная почта
      .basket__input-box
        input.basket__input.input.text(type="email", id="user-email", name="email", v-model="email", @input="eventChangeEmail")
        span.basket__small-text
          | {{ isAuth ? 'Вышлем чек' : 'Вышлем чек и пароль для входа в личный кабинет, в котором сохранится заказ' }}
</template>

<script>
import { getUser } from '../../assets/scripts/API'

export default {
  name: 'TheBasketInfo',
  emits: ['change-name', 'change-email', 'change-phone'],
  data () {
    return {
      isAuth: false,
      name: null,
      email: null,
      phone: null
    }
  },
  methods: {
    eventChangeName () {
      this.$emit('change-name', this.name)
    },
    eventChangeEmail () {
      this.$emit('change-email', this.email)
    },
    eventChangePhone () {
      this.$emit('change-phone', this.phone)
    }
  },
  async mounted () {
    const user = await getUser()
    this.isAuth = user.is_auth
    this.name = user.name
    this.email = user.email
    this.phone = user.phone
    this.eventChangeName()
    this.eventChangeEmail()
    this.eventChangePhone()
  }
}
</script>
