<template lang="pug">
.basket__top-block
  ul.basket__list
    li.basket__card(v-for="product in products", :key="product")
      .basket__card-body
        picture.basket__card-picture
          source(:srcset="`${product.images.w60} 1x, ${product.images.w120} 2x`", type="image/webp", media="(max-width: 700px)")
          source(:srcset="`${product.images.w128} 1x, ${product.images.w192} 1.5x`", type="image/webp")
          source(:srcset="`${product.images.j128} 1x, ${product.images.j192} 1.5x`")
          img.basket__card-image(:src="product.images.j128", :alt="`${product.name} - фото`", :title="`${product.name} - фото`", loading="lazy", width="128", height="128")
        .basket__card-name
          router-link.basket__card-link.text(:to="{ name: 'Product', params: { slug: product.slug } }") {{ product.name }}
          span.basket__card-offer.text(v-if="product.typeName") {{product.typeName}}
        .basket__card-box
          label.basket__card-amount
            .basket__card-button.basket__card-button_minus(:class="{ 'basket__card-button_disable': product.quantity < 2 }", @click="downCount(product)")
            input.basket__card-input(type="number", v-model="product.quantity", @change="changeProduct(product)")
            .basket__card-button.basket__card-button_plus(@click="upCount(product)")
          .basket__card-price-box
            span.basket__card-actual-price {{ product.price_current }}
            span.basket__card-old-price(v-if="product.price_old > product.price_current") {{ product.price_old }}
        .basket__card-message
          textarea.basket__card-textarea.text(placeholder="Пожелания к заказу...", v-model="product.comment", @change="changeProduct(product)")
        button.basket__card-delete(type="button", @click="deleteProduct(product)")
  .basket__total-block
    span.basket__total.text-big Итого:
      span.basket__total-price {{ totalPrice.toLocaleString() }}
    span.basket__total-amount.text Товаров:&ensp;
      span {{ totalCount }} шт.
</template>

<script>
import { refreshUser, removeProduct, setProduct } from '../../assets/scripts/API'

export default {
  props: {
    startProducts: Array
  },
  emits: ['change-products'],
  name: 'TheBasketProducts',
  data () {
    return {
      products: [],
      totalPrice: 0,
      totalCount: 0
    }
  },
  methods: {
    setProduct (product) {
      this.emitChangeProducts()
      setProduct(product.id, product.quantity, product.comment, product.type_id).then(refreshUser)
    },
    upCount (product) {
      const count = product.quantity += 1
      const comment = product.comment
      this.timeOut(count, comment, product)
    },
    downCount (product) {
      if (product.quantity < 2) return
      const count = product.quantity -= 1
      const comment = product.comment
      this.timeOut(count, comment, product)
    },
    changeProduct (product) {
      const count = product.quantity
      const comment = product.comment
      this.timeOut(count, comment, product)
    },
    deleteProduct (product) {
      this.products = this.products.filter(item => item.id !== product.id || item.type_id !== product.type_id)
      this.emitChangeProducts()
      this.countSum()
      removeProduct(product.id, product.type_id).then(refreshUser)
    },
    countSum () {
      this.totalPrice = 0
      this.totalCount = 0
      this.products.forEach(product => {
        this.totalPrice += product.price_current * product.quantity
        this.totalCount += product.quantity
      })
    },
    emitChangeProducts () {
      const products = this.products.map(product => (
        { id: product.id, count: product.quantity, comment: product.comment, typeId: product.type_id ? product.type_id : '' })
      )
      this.$emit('change-products', products)
    },
    timeOut (count, comment, product) {
      this.countSum()
      setTimeout(() => {
        if (count === product.quantity) {
          this.setProduct(product)
        }
        if (comment === product.comment) {
          this.setProduct(product)
        }
      }, 300)
    }
  },
  mounted () {
    this.products = this.startProducts.map(product => {
      this.totalPrice += product.price_current * product.quantity
      this.totalCount += product.quantity
      return product
    })
    this.emitChangeProducts()
  }
}
</script>
